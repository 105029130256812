import React, { useState, useEffect, useRef } from 'react'
import styles from '../../css/dashboard/calendar-chart.module.css'
import { useFilterContext } from '../../pages/logged-in/PageUserDashboard';
import { formatNumberK, formatTotalTime } from '../../functions/number-functions';
import { Percentage } from '../../functions/number-functions';

export default function CalendarChart({ data }) {

  const { axiosData, setAxiosData, setQueryParams } = useFilterContext()

  const [selectDataField, setSelectDataField] = useState('totalJumps');
  const [tooltip, setTooltip] = useState({
    value:null
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const toggleTooltip = (key, value) => {
    setTooltip({
        number:key,
        value: value
    })
    setShowTooltip(prevState => !prevState);
  };

  useEffect(() => {
    // Function to detect clicks outside the tooltip
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);  // Hide tooltip if clicked outside
        
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup: remove event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectField = (name) =>{
    setSelectDataField(name)
    setTooltip(false)
  }
 

 const handleMonthToggle = (number, year) =>{
      

  setQueryParams(prev => ({ 
    ...prev,
    chartCalendar:{fetch: "calendar", month:number, year: year}
  }))
  setAxiosData({
    restMethod: "GET",
    restRoutePath: "chart",
    postRoute: "chartCalendar",
    run: !axiosData.run
  })
}

  const maxTotal ={
      totalJumps: Math.max(...Object.values(data.dates).map(date => date.totalJumps)),
      totalTime: Math.max(...Object.values(data.dates).map(date => date.totalTime)),
      totalCalories: Math.max(...Object.values(data.dates).map(date => date.totalCalories))
  }
 

  return (
    <div className={styles['container']}>
        


        <div className={styles['main']}>
        <div className={styles['header']}></div>
        <div className={styles['content-chart']}>
            {data.dates && Object.entries(data.dates).map(([key, value]) => (
            
            <div 
            key={key}
            data-key={key}
                ref={tooltipRef}
                onClick={()=> toggleTooltip(key, value[selectDataField])} 
        
                className={`
                    ${styles['date']}
                    ${value[selectDataField] > 0 && styles['has-value']}
                `}>
                <div 
                className={
                    `${toggleTooltip && styles['tooltip']} 
                     ${styles['default-bar']} 
                     ${styles[selectDataField]}
                     `}>
                    <div 
                    style={{"height":Percentage(value[selectDataField], maxTotal[selectDataField])+'%'}}
                    className={styles['value']}>

                   
                    </div>
    
                </div>
                <div className={styles['label']}>
                    {value.day}
                    <b>{key}</b>
                </div>
            </div>
            ))}
        </div>

        </div>
        
        <div className={styles['footer']}>

        <button 
        name="prev"
        onClick={()=> data.prevButton['found'] ? handleMonthToggle(data.prevButton['number'], data.prevButton['year']) : null}
        className={`${styles['button-prev']} ${styles[data.prevButton['found']]}`}>
          {data.prevButton['name']} '{data.prevButton['year']}
          </button>


        <div className={styles['content-grid']}>
          
          <div className={styles['column']}>
          <strong>{data.month}</strong>
          {data.year}
          </div>


          <div className={styles['column']}>
          <strong>{data.totalCount}</strong>
          Activity Days
          </div>

          <button 
          onClick={()=> handleSelectField('totalJumps')}
          className={`${styles['column']} ${styles['totalJumps']} ${styles[selectDataField === 'totalJumps' && true]}`}>
          <strong>{(tooltip.value && selectDataField === 'totalJumps') ? formatNumberK(tooltip.value, 1000000) : data.totalJumps}</strong>
          Total Jumps
          </button>
     

          <button 
          onClick={()=> handleSelectField('totalTime')}
          className={`${styles['column']} ${styles['totalTime']} ${styles[selectDataField === 'totalTime' && true]}`}>
          <strong>{(tooltip.value && selectDataField === 'totalTime') ? formatTotalTime(tooltip.value, 'timecode') : data.totalTime}</strong>
          Total Time
          </button>

          <button 
          onClick={()=> handleSelectField('totalCalories')}
          className={`${styles['column']} ${styles['totalCalories']} ${styles[selectDataField === 'totalCalories' && true]}`}>
           <strong>{(tooltip.value && selectDataField === 'totalCalories') ? formatNumberK(tooltip.value, 1000000) : data.totalCalories}</strong>
          Total Calories
          </button>


        </div>

        <button 
        onClick={()=> data.nextButton['found'] ? handleMonthToggle(data.nextButton['number'], data.nextButton['year']) : null}
        className={`${styles['button-next']} ${styles[data.nextButton['found']]}`}>
          {data.nextButton['name']} '{data.nextButton['year']}
        </button>
       
      </div>
    </div>
  )
}

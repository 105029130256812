import React from 'react'
import styles from './../../css/dashboard/result-item.module.css';
import { Percentage } from '../../functions/number-functions';

export default function BarChartWeek({ isSelected, data }) {
  const dates = Array.from({ length: 7 }, (_, index) => index + 1);
  const dayname = ["mo","tu","we","th","fr","sa","su"]


  return (
    <div className={styles['bars']}>
       {dates.map((number, index) => (
        <div key={index} className={styles['block']}>
            <div className={styles['value']}>
                <div 
                style={{'height': data.dates[number] ? Percentage(data.dates[number], data['max'])+'%' : 0}}
                className={`${styles['percent-value']} 
                ${isSelected === 'daily' && (data.current === number) && styles[true]}
                ${isSelected === 'weekly' && (data['dates'].hasOwnProperty(number)) && styles[true]}
                `}></div>
            </div>
            <div className={styles['label']}>{dayname[index]}</div>
        </div>
       ))}
    </div>
  )
}

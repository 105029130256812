
const userAuthentication = (data) => {

  const { username, id } = data;
  const userData = { username, id};

  if(!localStorage.getItem('user')) {
      localStorage.setItem('user', JSON.stringify(userData));    
      
  }
  
}

//functions that validates Username a-zA-Z0-9_-
function validateUsername(username) {
  const regex = /^[a-zA-Z0-9_-]{3,20}$/;
  return regex.test(username);
}

//Check to see if an Email is a valid format
const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

const getUserTimezone = () =>{
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const getUserTimezoneDate = () =>{
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();
  const timezoneDate = new Date(currentDate.getTime() - (offset * 60 * 1000));
  return timezoneDate
}


const getUserBrowser = () =>{
return navigator?.userAgent?.match(/C[a-zA-Z]{1,10}/)?.[0];
}


  export{
    userAuthentication,
    validateUsername,
    validateEmail,
    getUserTimezone,
    getUserTimezoneDate,
    getUserBrowser
  }
import React, {useEffect, useState} from 'react'
import { style_user } from '../../css'

export default function FormPasswordStrength({ password }) {

    const [strength, setStrength] = useState()
  


    function check_strength(str) {

        let hasLowerCase = /[a-z]/.test(str);
        let hasUpperCase = /[A-Z]/.test(str);
        let hasLetter = /[a-zA-Z]/.test(str);
        let hasNumber = /\d/.test(str);
        let hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
        
        let count = 0;

        if(hasLowerCase){
            count++;
        }
        if(hasUpperCase){
            count++;
        }
        if(hasNumber){
            count++;
        }
        if(hasSymbol){
            count++;
        }

        if(count > 0){
        setStrength(count)
        }else{
        setStrength(false)
        }
        
    }


    const strength_color = {1:"#db1226", 2:"#f2ac3a", 3:"#ace85d", 4: "#22a611"}

    useEffect(() => {
      
    if(password.length > 0){

      check_strength(password)

    }else{
        setStrength(false)
    }
    
    }, [password])

    const password_strength = () =>{
        const a = []
        for(let i = 1; i <= 4; i++){
           a.push(
            <div
            style={{backgroundColor: strength >= i ? strength_color[strength] : "#cbd2e4"}} 
            className={style_user['meter']}>
            </div>)
        }
        return a;
    }

  return (
    <div className={style_user['password-strength']}>
        {password_strength()}
    </div>
  )
}

import React, {useEffect, useState, useRef} from 'react'
import style from '../css/main.module.css'

export default function PageHome() {

    const [isVisible, setIsVisible] = useState(true);

    const bottomHeightRef = useRef(null);
  
    useEffect(() => {
      function handleScroll() {
        const currentScrollPos = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const scrollHeight = document.body.scrollHeight;
  
        const bottomHeight = bottomHeightRef.current.getBoundingClientRect().top + window.scrollY;


  
  
        if (currentScrollPos > bottomHeight + 250) {
          setIsVisible(false);
        }else{
          setIsVisible(true);
        }
      }
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  


  

    function getScrollbarWidth() {
      // Create a div with a scrollbar
      const div = document.createElement("div");
      div.style.width = "50px";
      div.style.height = "50px";
      div.style.overflow = "scroll";
      document.body.appendChild(div);
      
      // Calculate the scrollbar width
      const scrollbarWidth = div.offsetWidth - div.clientWidth;
      
      // Remove the div from the DOM
      document.body.removeChild(div);
      
      // Set the value of the --scrollbar-width variable
      document.documentElement.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
    }
    
    // Call the function to get the scrollbar width
    getScrollbarWidth();


  return (
    <div className={style['home-container']}>

   

        


    </div>
  )  
}

import { ImArrowUp, ImArrowDown } from 'react-icons/im';

function formatNumberK(number, shortenIfExceeds) {
    let suffix = "";

    // Check if the number should be shortened based on the shortenIfExceeds parameter
    if (number >= 1000000000 && shortenIfExceeds <= 1000000000) {
        number = Math.round(number / 1000000000 * 10) / 10;
        suffix = "b";
    } else if (number >= 1000000 && shortenIfExceeds <= 1000000) {
        number = Math.round(number / 1000000 * 10) / 10;
        suffix = "m";
    } else if (number >= shortenIfExceeds && number <= 9999999) {
        number = Math.floor(number / 100) / 10;  // Keep one decimal place without rounding
        suffix = "k";
    }

    // Format the number to one decimal place
    let formattedNumber = number.toFixed(1);

    // Check if the decimal is ".0" and remove it if present
    if (formattedNumber.endsWith(".0")) {
        formattedNumber = number.toFixed(0);
    }

    // Convert the formatted number to include commas for thousands separators
    if (suffix === "") {
        formattedNumber = number.toLocaleString();
    } else {
        formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return formattedNumber + suffix;
}




const formatTotalTime = (num, action) =>{

    var h = Math.floor(num / 3600).toString().padStart(2, '0');
    var m = Math.floor(num % 3600 / 60).toString().padStart(2, '0');
    var s = Math.floor(num % 3600 % 60).toString().padStart(2, '0');
    
    switch(action) {
        case "h-m":
        return h+'h '+m+'m';
        break;
        case "m-s":
        return m+':'+s;
        break;
        case "timecode":
        return h+':'+m+':'+s;
        break;
        default: 
        break;
    }


}

const minutesAndSeconds = (value) =>{
    let number = Math.abs(value)
    let minutes = Math.floor(number / 60) > 0 ? Math.floor(number / 60)+'m' : '';
    let seconds = number % 60 > 0 ? number % 60+'s' : '';
    let format_value = minutes+' '+seconds;
    return value > 0 ? '+'+format_value : '-'+format_value;
}

function Decimals(number, limit){
    return +number.toString().slice(0, (number.toString().indexOf(".")) + (limit + 1));  
 }


/* Function that gets the percentage of a number with the option of
rounding it to a specific decimal number */
 function Percentage(num, total, decimals) {
    let number = (num / total) * 100;
    if(decimals){
    return number.toString().slice(0, (number.toString().indexOf(".")) + (decimals + 1));  
    }else{
    return Math.trunc(number);
    }
}

/* Function takes a number and divde it by count to get an average */
function numberToAverage(value, count){
    let cnt = Object.keys(count).length
    return (value/cnt);
}

const percent_change = (number) => {   
      let decimal = Math.abs(number).toFixed(1).split('.')[1]; 
      let decimal_num = Number(decimal) === 0 ? 0 : 1;
      if(number > 0){
      return <div style={{fontSize:11, color:"#07ad25"}}><ImArrowUp size="8" style={{marginRight:2}} color="#07ad25"/>{number.toFixed(decimal_num)+'%'}</div>
      }else if(number < 0){
      return <div style={{fontSize:11, color:"#fa6969"}}><ImArrowDown size="8" style={{marginRight:2}} color="#ed5a5a"/>{number.toFixed(decimal_num)+'%'}</div>
      }
}

/* This function sums up the total value of an array */
const array_sum = (arr) => {
    const sum = arr.reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue);
    }, 0);
    return sum;
}

const percentDiff = (current, prev) =>{
    if (current === 0 && prev !== 0) {
        return 100 * -1; // percentage change is 100% when current is 0 and prev is nonzero
      } else if (prev === 0) {
        return 0; // percentage change is undefined when prev is 0
      } else {
      let value = ((prev - current) / current) * 100;
      return Math.abs(value.toFixed(1));
        //return Decimals((1 - prev / current) * 100, 1); // calculate percentage change normally
      }
 }



 function convertKgToLbs(value){
    let conversion = value * 2.20462;
    return conversion.toFixed(1)
 }


 function convertSecondsToHoursMinutes(seconds) {
    if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        
        if (minutes === 0) {
          return `${hours}h`;
        } else {
          return `${hours}h ${minutes}m`;
        }
      } else {
        const minutes = Math.floor(seconds / 60);
        return `${minutes}m`;
      }
  }
export { 
    formatNumberK,
    formatTotalTime,
    minutesAndSeconds,
    Percentage,
    percent_change,
    Decimals,
    numberToAverage,
    array_sum,
    percentDiff,
    convertKgToLbs,
    convertSecondsToHoursMinutes
}
import React from 'react'
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <div className="page-not-found">
    <h1>Oops! you tripped up!</h1>
    <span>The page you requested could not be found</span>
    <Link to="/" className="back-home">Back Home</Link>
    </div>
  )
}

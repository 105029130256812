import React from 'react'
import { validateUsername, validateEmail } from '../../functions/user-functions';

export default function FormCustomInput({form, setForm, field, placeholder, type, maxLength, validate}) {

    const field_name = field.toLowerCase().replace(/\s+/g, "-");

    const handleInput = (event) =>{
        const name = event.currentTarget.getAttribute('name');
        const value = event.currentTarget.value;
        if(name === 'username'){
        validateUsername(value) ? setForm({ ...form, username:value, validation:{ ...form.validation, username:true}}) : 
                                  setForm({ ...form, username:value, validation:{ ...form.validation, username:false}})
        }else if(name === 'email'){
        validateEmail(value) ? setForm({ ...form, email:value, validation:{ ...form.validation, email:true}}) : 
                               setForm({ ...form, email:value, validation:{ ...form.validation, email:false}})
        }else if(name === 'password'){
        setForm({ ...form, password:value})
        }else if(name === 'confirm_password'){
        setForm({ ...form, confirm_password:value})
        }
    }

  return (
    <input 
    name={field}
    placeholder={placeholder}
    type={type}
    value={`${form[field_name]}`}
    onChange={handleInput}
    maxLength={maxLength} 
    />
  )
}

import React from 'react'
import styles from './../../css/dashboard/result-item.module.css';

export default function SmallCalendarMonth({ daysInMonth, startDay, foundDates }) {
   
    const dates = Array.from({ length: daysInMonth }, (_, index) => index + 1);
    const blanks = Array.from({ length: startDay - 1}, (_, index) => index + 1);
    const daysOfWeek = ["Mo","Tu","We","Th","Fr","Sa","Su"]
  return (
    <div className={styles['calendar']}>
         {daysOfWeek.map((name, index) => (
            <div className={styles['num-day']}>{name}</div>
         ))}
         {startDay > 1 && blanks.map((number, index) => (
           <div className={styles['num-blank']}></div>
         ))}
         {dates.map((number, index) => (
              foundDates.hasOwnProperty(number) ? (
              <div className={styles['num-date-active']}>{number}</div>
              ) : (
              <div className={styles['num-date']}></div> 
              )
         ))}
    </div>
  )
}

import React from 'react'
import settingsCSS from '../css/settings.module.css'
import { MdAlternateEmail } from 'react-icons/md';
import { BsFillShieldLockFill } from 'react-icons/bs';

export default function UserSettings() {
  return (
    <div className={settingsCSS['container']}>
        <h4>Settings</h4>

        <div className={settingsCSS['content']}>

        <div className={settingsCSS['element']}>
        <div className={settingsCSS['title']}>Email <MdAlternateEmail size="15" color="#80a7cf"/></div>
        <input type="text" className={settingsCSS['input-field']}/>
        </div>
        

        <div className={settingsCSS['element']}>
        <div className={settingsCSS['title']}>Change Password <BsFillShieldLockFill size="15" color="#80a7cf"/></div>
        <div className={settingsCSS['input-label']}>Current Password</div>
        <input type="text" className={settingsCSS['input-field']}/>
        <div className={settingsCSS['new-password']}>
            
            <div className={settingsCSS['field']}>
            <div className={settingsCSS['input-label']}>New Password</div>
            <input type="text" className={settingsCSS['input-field']}/>
            </div>
            
            <div className={settingsCSS['field']}>
            <div className={settingsCSS['input-label']}>Confirm Password</div>
            <input type="text" className={settingsCSS['input-field']}/>
            </div> 

        </div>
        
        </div>

        <div className={settingsCSS['element']}>
        <div className={settingsCSS['title']}>Bodyweight (Unit)</div>
        <p>Display your weight in kilograms (KG) or pounds (LBS)</p>
        
        <div className={settingsCSS['checkbox-options']}>

        <div className={settingsCSS['checkbox']}>
        <input
        type="checkbox"
        checked={true} 
        />
        KG
        </div>

        <div className={settingsCSS['checkbox']}>
        <input
        type="checkbox"
        checked={false} 
        />
        LBS
        </div>

        </div>
        
        </div>

        {/* Deactivate Account */}
        <div className={`${settingsCSS['element']} ${settingsCSS['deactivate']}`}>
            <p>If you choose the deactivate your account you will be logged out automatically. You’ll have 30 days
            reactive your account at anytime by logging back in. After 30 days your account and data will be deleted.</p>
            
            <div className={settingsCSS['row']}>
            <div className={settingsCSS['button']}>Deactivate Account</div>
            </div>
           
        </div>
        
        <button className={settingsCSS['save-button']}>Save</button>
        </div>

        

    </div>
  )
}

import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { getUsername, getUserDate } from '../../getUser'
import style from '../../css/Header.module.css'
import { BiBell, ImHome, IoMdAddCircle, TiUser } from '../../assets/icons';
import { UserContext } from '../../App';

export default function UserHeader() {

  const {user, setUser} = useContext(UserContext);

  const navigate = useNavigate();
  const menuRef = useRef(null);
  
  const [showMenu, setShowMenu] = useState(false)
   
   const userExit = () =>{
   localStorage.clear();
   setUser(false)
   navigate('/');

   }
 
   useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div ref={menuRef} className={style['loggedin-nav']}>
    
    <div className={style['loggedin-buttons']}>
  

    <Link to="/dashboard" className={`${style['loggedin-button']} ${style['btn-dashboard']}`}>
    <ImHome size="20" />  
    </Link>

    <div className={`${style['loggedin-button']} ${style['btn-notification']}`}>
    <BiBell size="20" /> 
    </div>
    </div> 

        <div 
        onClick={() => setShowMenu(!showMenu)}
        className={style['user-dropdown']}>
        <TiUser size="26" color="#9099BA"/>
        </div>
        
      <div className={`${style['user-dropdown-menu']} ${showMenu ? style.show : style.hide}`}>
      
      <div className={style['user-info']}>
      <b>{user.username}</b>
      <div className={style['u-email']}>Member since: {getUserDate()}</div>
      </div>

     
     <Link to="/settings" style={{ textDecoration: 'none' }}>
     <button className={`${style['button']} ${style['settings']}`}>
     Account Settings
     </button>
     </Link>

     <button onClick={userExit} className={`${style['button']} ${style['logout']}`}>
     Log Out
     </button>
      </div> 
  
    </div>
  )
}

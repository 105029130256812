import React from 'react'

export default function PagePrivacyPolicy() {
  return (
    <div className="content">
    <h2>Privacy Policy</h2>

 
    <p>
    <strong>Information we collect</strong>
    When you visit our website, we may collect certain information about you, including your IP address, 
    browser type, language, and the date and time of your visit. We may also collect information about your 
    behavior on our website, such as the pages you visit and the links you click on <br/><br/>
    If you choose to create an account on our website, we will collect your email address, and password. 
    </p>

    <p>
    <strong>How we use your information</strong>
    We use your personal information to provide you with the services you have requested, such as processing
    your orders and communicating with you about your account. We may also use your information to improve our website, 
    personalize your experience, and analyze how our website is used.<br/><br/>
    We will not share your personal information with third parties for their marketing purposes without your consent.
    </p>

    <p><strong>Your choices</strong>
    You may choose not to provide us with certain information, but this may limit your ability to use our website or 
    access certain features.<br/><br/>
    You may also update or delete your account information at any time by logging into your account and following instructions.</p>

    <p><strong>Changes to this policy</strong>
    We may update this privacy policy from time to time. We will notify you of any material changes by posting the updated policy on our website or by email.
    We encourage you to review this policy periodically.</p>
    

    </div>
  )
}

import React from 'react'
import styles from './../../css/dashboard/filter-table.module.css';
import { Percentage } from '../../functions/number-functions';

export default function FilterMonth({ data }) {

  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const monthnames = ["January","February","March","April","May","June","July", "August", "September", "October", "November", "December"]

  return (
    <div className={styles['filter-month']}>
       {monthnames.map((name, index) => (
        <div key={index} className={styles['block']}>
          
            <div className={styles['value-row']}>
            <div 
            style={{'bottom': data[name] ? Percentage(data[name], data['max'])+'%' : 0}}
            className={styles['count']}>{data[name]}</div>
                <div 
                style={{'height': data[name] ? Percentage(data[name], data['max'])+'%' : 0}}
                className={`${styles['percent-value']}`}></div>
            </div>
            <div className={styles['label']}>{month[index]}</div>
        </div>
       ))}
    </div>
  )
}

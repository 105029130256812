const buildQueryString = (obj) =>{
    const queryString = Object.keys(obj)
    .filter(key => obj[key] !== null && obj[key] !== "")
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
    return queryString
}



export{
    buildQueryString
}
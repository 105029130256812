import { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { style_user } from '../css'
import { UserContext } from '../App';
import { FiUser, MdOutlineArrowRightAlt, BsFillEyeFill, BsFillEyeSlashFill, HiLockClosed, MdError } from '../assets/icons';
import { isEmpty } from '../includes/general';
import { validateEmail, getUserTimezone } from '../functions/user-functions';
import { userAuthentication } from '../functions/user-functions';

export default function AccountLogin() {

  const {user, setUser} = useContext(UserContext);

  const URL = process.env.REACT_APP_RESTAPI;

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url_param = searchParams.get('user');


  
  const [showPassword, setShowPassword] = useState(false)


  const [errors, setErrors] = useState(false)

  const form_reset = {
    user:'',
    password:'',
    reset_password:'',
    status:false
  }
  const [form, setform] = useState({
    user:'',
    password:'',
    reset_password:'',
    status:false
  })
  
  useEffect(() => {
    setform(form_reset);
    setErrors(false);
  },[url_param])

  //Password Reset: Submit
  const resetPassword = (event) => {
     event.preventDefault();
     let reset_password = form.reset_password;
     if(isEmpty(reset_password) || (!validateEmail(reset_password))){
         setErrors({ ...errors, reset_password:{error:true, message:'Enter a valid Email Address'}})  
     }else{
      axios.post(`/reset_password`, {email:reset_password}, { withCredentials: true })
      .then(({data}) => {
          if(data.error){
            setErrors({ ...errors, reset_password:{error:true, message:data.message}})   
          }
      }).then(() => console.log('hi'))
          
     }

  }
  //localStorage.removeItem('user');

  /* START -> Submit User Login Form */
  const userLogin = (event) => {
    event.preventDefault();
    setErrors(false);

    const param = {
        user: form.user,
        password: form.password,
       // web_browser: navigator?.userAgent?.match(/C[a-zA-Z]{1,10}/)?.[0],
       // timezone: getUserTimezone()
    };

    axios.post(`${URL}/api/user/login`, param, { withCredentials: true })
    .then(({data}) => {
      if(data.hasOwnProperty("user-login")){
        
        localStorage.setItem('user', JSON.stringify(data['user-login']));
        // Redirect to the desired route, e.g., '/dashboard'
        navigate('/dashboard');
        // Reload the page
        window.location.reload(); // This will reload the page after navigation
    }
      
    })
    .catch(error => {
      //console.error(error)
    })
  
  
   }
  /* END -> Submit User Login Form */

  
  const saveUserLogin = (obj) =>{

    //Data to save in the Local Storage
    const credentials = {
      username:obj.username, //Username
      id:obj.id, //User Id
   //   user_email:obj.email, //User Email
     // user_date:obj.date //User Join Date
    }

   
      if(userAuthentication(credentials)){ 
      navigate('/user/home'); //After successful login navigate to User Dashboard
      }

  }






   const handleInputChange = event => {
    const { name, value } = event.target;
    setform({ ...form, [name]: value });
  };

  return (

    <div className={style_user['container']}>
    {url_param === 'reset_password' ? (
      <div className={`${style_user['account']} ${style_user['reset-password']}`}>
        <h5>Reset Password</h5>
        <Link to="/account/login" className={style_user['password-link']}>Back to login</Link>
        <p>Please enter the email address you used to register. We will send you a link to reset your password.</p>
        <form onSubmit={resetPassword}>
        <input 
        name="reset_password"
        value={form.reset_password}
        onChange={handleInputChange}
        type="text" 
        placeholder="Email Address"/>
         {errors.reset_password['error'] && 
         <div className={style_user['error-msg']}><MdError size="15" color="#f35b5b" className={style_user['icon']}/>  {errors.reset_password['message']}</div>
         }
        <button type="submit" className={style_user['button']}>Send</button>
        </form>
      </div>
    ) : (
    <div className={style_user['account']}>
    <h5>Login</h5>
    <p>If you're an existing user, please login using your Username/Email and Password to access your account.</p>
   
    <form onSubmit={userLogin} style_user={{marginTop:20,marginBottom:10}}>

    <div className={style_user['field-content']}>
      <div className={style_user['field-label']}>Username or Email</div>
      
      <div className={`${style_user['input-field']} ${errors && !form.user && style_user['error']}`}>


      <div className={style_user['input-icon']}><FiUser size="20" color="#597195"/></div> 
      <input 
      name="user"
      value={form.user}
      onChange={handleInputChange}
      type="text" 
      placeholder="Username or Email"/>
      </div>
    </div>

    <div className={style_user['field-content']}>
    <div className={style_user['field-label']}>Password</div>
    <div className={`${style_user['input-field']} ${errors && !form.password && style_user['error']}`}>
    <div className={style_user['input-icon']}><HiLockClosed size="20" color="#597195"/></div>  
    <input
    name="password"
    value={form.password}
    onChange={handleInputChange} 
    type={showPassword ? 'text' : 'password'} 
    placeholder="Password"/>
    <div className={style_user['show-password']}
    onClick={()=> setShowPassword(!showPassword)}
    >
    {showPassword ? <BsFillEyeFill size="20" color="#6f88ad"/> : <BsFillEyeSlashFill size="20" color="#6f88ad"/>}</div> 
    </div>

    </div>
    <Link to={null} className={style_user['password-link']}>Forgot your password?</Link>

    <button type="submit" className={style_user['button-login']}>Login <MdOutlineArrowRightAlt size="15" color="#fff" /></button>

    {errors && (
      <div className={style_user['error-msg']}>
      <MdError size="20" color="#f35b5b" className={style_user['icon']}/>
      <span>{errors}</span>
      </div>
    )
    }
    </form>
     


    </div>
    )}

    </div>
   
 

  )
}

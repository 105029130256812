function isEmpty(str) {
    if ((typeof(str) === "undefined") || (!str || /^\s*$/.test(str))){
      return true;
    }
  } 

  function getArrayLength(array){
    return array.length;
  }

  function firstLetterCapitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  export{
    isEmpty,
    getArrayLength,
    firstLetterCapitalize
  }
import React from 'react'
import styles from './../../css/dashboard/activity-map-chart.module.css';
import { useFilterContext } from '../../pages/logged-in/PageUserDashboard';

export default function ActivityMapChart({ data }) {


  const { axiosData, setAxiosData, setQueryParams } = useFilterContext()
    
      // const days =  data.daysInYear;
       const year = data.year;

       const columns = 7; // Number of columns
       const rows = 52; // Number of rows per column
       const start_day = data.startDay;
      // const weeksInYear = data.weeksInyear;

       const objectDays = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
       const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


       const mapDates = data.dates;
       //const found_dates = Object.keys(activity_dates);

       //console.log(activity_dates)


     
   // Convert date strings to Date objects and get day of the week
   const getSortedDayCounts = (dates) => {
    const dayCounter = dates.reduce((counter, dateString) => {
      const date = new Date(dateString);
      const day = date.toLocaleDateString('en-US', { weekday: 'short' });

      counter[day] = (counter[day] || 0) + 1;
      return counter;
    }, {});

    const sortedDayCounter = Object.fromEntries(
      Object.entries(dayCounter).sort((a, b) => {
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        return daysOfWeek.indexOf(a[0]) - daysOfWeek.indexOf(b[0]);
      })
    );

    return sortedDayCounter;
  };

  function getLastDayOfYear(year) {
    const lastDayOfYear = new Date(year, 11, 31); // December 31st of the specified year
    let dayOfWeekNumeric = lastDayOfYear.getDay();
    // Adjust the numeric value to represent Monday as 0 and Sunday as 6
    dayOfWeekNumeric = (dayOfWeekNumeric + 6) % 7;
    return dayOfWeekNumeric;
  }

  //const sortedDayCounter = getSortedDayCounts(found_dates);

       function getDatesInYear(year) {
        const datesInYear = {
          1: [], // Monday
          2: [], // Tuesday
          3: [], // Wednesday
          4: [], // Thursday
          5: [], // Friday
          6: [], // Saturday
          0: []  // Sunday
        };
      
        // Loop through all the months of the year (0 to 11 for January to December)
        for (let month = 0; month < 12; month++) {
          // Get the last day of the month (date starts from 1, so set day to 0 to get the last day of the previous month)
          const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
      
          // Loop through the days of the month (1 to last day of the month)
          for (let day = 1; day <= lastDayOfMonth; day++) {
            // Create a Date object for the current date
            const currentDate = new Date(year, month, day);
            // Get the numeric representation of the day (0 for Sunday, 1 for Monday, etc.)
            const dayNumber = (currentDate.getDay() + 6) % 7;
            // Format the date as "YYYY-mm-dd"
            const formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            // Add the date to the array of the corresponding day in the datesInYear object
            datesInYear[dayNumber].push(formattedDate);
          }
        }
      
        return datesInYear;
      }
      
      // Example usage for the year 2023
      const lastDayOfYear = getLastDayOfYear(year); // December 31st of the specified year
      const datesInYear = getDatesInYear(year);

   

  const renderGrid = () =>{
    const grid = [];
    
    for (let col = 0; col < columns; col++) {
      const column = [];



      const endDateDay = datesInYear[col][datesInYear[col].length - 1];
       
      const dayLabel = objectDays[col].charAt(0)

      if(col < start_day){
       
         
        column.push(<div key={`blank-${col}`} className={`${styles['grid-cell']} ${styles['blank']}`}></div>)
      }
        datesInYear[col].map((date, index) => {

          column.push(
            <div key={`col-${col}-date-${index}`} className={`${styles['grid-cell']} ${mapDates.includes(date) ? styles['found'] : ['default']}`}>
             {}
            </div>
          );

    
         if((endDateDay === date) && (col > lastDayOfYear)){
          column.push(<div key={`blank-${col}`} className={`${styles['grid-cell']} ${styles['blank']}`}></div>)
        }

    })


       

      grid.push(
        <div key={col} className={styles['grid-column']}>
          {column}
        </div>
      );
    }
    return grid;
  }

  const value_suffix = (value) =>{
       if(value === 'completed'){
        return '%';
       }else{
        return '';
       }
  }


  const handleYearToggle = (year) =>{

    setQueryParams(prev => ({ 
      ...prev,
      "chartMap":{fetch:"map",year:year}
    }))
    setAxiosData({
      restMethod: "GET",
      restRoutePath: "chart",
      postRoute: "chartMap",
      run: !axiosData.run
    })

  }


  return (
    <div className={styles['container']}>
        



        <div className={styles['content']}>

            <div className={styles['main']}>
                 <div className={styles['months']}>
                 {months.map((month, index) => (
                    <div key={index} className={styles['month-name']}>{month}</div>
                 ))}
                 </div>
                 
                 <div className={styles['renderGrid']}>{renderGrid()}</div>
               
                
               
                
                </div>

                  
       
        </div>




        <div className={styles['footer']}>
        
        <button 
        name="prev"
        onClick={()=> data.prevButton['found'] ? handleYearToggle(data.prevButton['year']) : null}
        className={`${styles['button-prev']} ${styles[data.prevButton['found']]}`}>
          {data.prevButton['year']}
        </button>

        <div className={styles['content-grid']}>
          
          <div className={styles['column']}>
          <strong>{data.year}</strong>
          Year
          </div>

          <div className={styles['column']}>
          <strong>{data.totalCount}</strong>
          Activity Days
          </div>

          <div className={styles['column']}>
          <strong>{data.totalJumps}</strong>
          Total Jumps
          </div>

          <div className={styles['column']}>
          <strong>{data.totalTime}</strong>
          Total Time
          </div>

          <div className={styles['column']}>
          <strong>{data.totalCalories}</strong>
          Total Calories
          </div>


        </div>
        <button 
        onClick={()=> data.nextButton['found'] ? handleYearToggle(data.nextButton['year']) : null}
        className={`${styles['button-next']} ${styles[data.nextButton['found']]}`}>
          {data.nextButton['year']}
        </button>
      </div>

        
    </div>
  )
}

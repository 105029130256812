import React from 'react'

export default function PageAbout() {
  return (
    <div className="page-container">
      <h3>About</h3>
      <p>Here at Discover The Jump, we believe that jumping rope is an excellent form of exercise that can help you achieve your 
        fitness goals quickly and efficiently. Whether you're a beginner or an experienced athlete, jumping rope is a 
        great way to improve your endurance, agility, coordination, and overall fitness.</p>

      <p>So, whether you're looking to get in shape, improve your athletic performance, or just have fun, you've come 
         to the perfect place. Join our community today and start logging your workouts, setting goals, and achieving 
         your fitness dreams!</p>  
    </div>
  )
}

import React, {useState, useEffect, useRef, createContext, useContext } from 'react'
import axios from 'axios';
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { BsCalendar2WeekFill } from "react-icons/bs";
import { IoFilter } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import dashboard from './../../css/Dashboard.module.css';
import TableResultItem from '../../components/result-table/TableResultItem';
import Pagination from '../../components/Pagination';
import { buildQueryString } from '../../functions/url-functions';
import PageUserWorkout from './PageUserWorkout';
import FilterDay from '../../components/dashboard/FilterDay';
import FilterMonth from '../../components/dashboard/FilterMonth';
import { useUserContext } from '../../App';
import ResultMonthHeader from '../../components/result-table/ResultMonthHeader';
import ActivityForm from '../../components/dashboard/ActivityForm';


export const FilterContext = createContext();

function PageUserDashboard() {
  const URL = process.env.REACT_APP_RESTAPI;

  const { setPopupBackground, user } = useUserContext();

  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState({
      table:true
  });
  const [chartLoading, setChartLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);

  const [resultChart, setResultChart] = useState(false)
  const [calendarChartData, setCalendarChartData] = useState(false)

  const [sendPostData, setSendPostData] = useState(null)

  const [popupModalWorkout, setPopupModalWorkout] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  const [tableFilters, setTableFilters] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
      dateDropdown: null
  });

  const [axiosData, setAxiosData] = useState({
    run: true
  })
  const [results, setResults] = useState('');
  const [userWorkout, setUserWorkout] = useState('');

  const [pagination, setPagination] = useState({
    current_page:1
  });
  const [queryParams, setQueryParams] = useState({
    'table':{select:"daily",sortBy:"datetime", "orderBy":"desc", "page":1}
  });


  const popupRef = useRef(null); // Ref for the popup component

    // Close popup when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
              setFilterOptions(prev => ({
                ...prev,
                dateDropdown: null
              }))
    
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);
        
        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

  const handlePostData = (data) =>{
    const { postRoute } = axiosData;
    const resultData = data[postRoute];

    


    const PostRouteKey = {
      "table": () => {
        Object.entries(resultData).forEach(([key, value]) => {
          console.log(key, value)

          if(key === "results"){
            setResults(value)
          }else if(key === "pagination"){
            setPagination(value)
          }else if(key === "filters"){
            setTableFilters(value)
          }
    
          })
          setLoading(prev => ({ 
            ...prev,
            table: false
          }));
      },
         "new-workout": () => {
          Object.entries(resultData).forEach(([key, value]) => {

            setUserWorkout(prev => ({ 
              ...prev,
              [key]: value 
            }))
      
            })
            setModalLoading(false)
         },
         "chartCalendar": () => {
          Object.entries(resultData).forEach(([key, value]) => {
            setCalendarChartData(prev => ({ 
              ...prev,
              [key]: value 
            }))
          })
           setChartLoading(false)
         },
         "chartMilestones": () => {
          Object.entries(resultData).forEach(([key, value]) => {
            setResultChart(prev => ({ 
              ...prev,
              [key]: value 
            }))
      
          })
          setChartLoading(false)
         },
         "chartMap": () => {
          Object.entries(resultData).forEach(([key, value]) => {
            setResultChart(prev => ({ 
              ...prev,
              [key]: value 
            }))
          })
            setChartLoading(false)
         }
    }

   const pathFunction = PostRouteKey[postRoute];
      
      if(pathFunction) {
        pathFunction();
      }


  }

  

  const handleResults = (data) =>{

    const res = data['table'];
    if(data.hasOwnProperty("table")){
      setResults(res.results)
      setPagination(res.pagination)
      setTableFilters(res.filters)
      setLoading(false)
    }
  
  }

  useEffect(() => {
 
    let fetchResults = async () => {
        try {
          const  { restMethod, restRoutePath, postRoute } = axiosData
        

    
  
           // Set the common headers for all requests
          const headers = {
            'X-USERID': user.id,
          };

          if(initialLoad){
          const [resultChart, tableResults] = await Promise.all([
            axios.get(`${URL}/api/dashboard/chart?fetch=calendar`, { headers }),
            axios.get(`${URL}/api/dashboard/result-table?select=daily&sortBy=datetime&orderBy=desc&page=1`, { headers }),
          ]);
          


            if(tableResults.data.hasOwnProperty("table") && resultChart.data.hasOwnProperty("chartCalendar")){
            setCalendarChartData(resultChart.data['chartCalendar']);
            handleResults(tableResults.data);
            setChartLoading(false)
            setInitialLoad(false)
            }

          }else{
          
            const queryString = buildQueryString(queryParams[postRoute]);

           
          
            const requestOptions = {
              method: restMethod,
              url: restMethod === "GET" ? `${URL}/api/dashboard/${restRoutePath}?${queryString}`
              : `${URL}/api/dashboard/${restRoutePath}`,
              withCredentials: true,
              headers: user.id
              ? { Authorization: `Bearer ${user && ''}`, 'X-USERID': user && user.id }
             : null,
             ...(restMethod === 'POST' || restMethod === 'PATCH' || restMethod === 'DELETE' ? { data: sendPostData } : {}),
            };
            const response = await axios(requestOptions);
            const data = response.data;
   
            
              if(!data.hasOwnProperty('error') && data.hasOwnProperty(postRoute)){
                  handlePostData(data) 
                  setLoading(false)

              }else{
                 
              }
           
          }

       
       
        
        } catch (error) {
          console.error(error);
        } finally {
        


          
        }
    }

    fetchResults();


  }, [axiosData.run]);  


  useEffect(() => { 
    if(pagination.current_page !== queryParams['table'].page){
    setQueryParams(prev => ({
      ...prev,
      'table':{
        ...prev['table'], 
        page:pagination.current_page
      }
    }))

    setAxiosData(prev => ({
      ...prev,
      restMethod: "GET",
      restRoutePath: "result-table",
      postRoute: "table",
      run:!prev.run
    }))

  }
}, [pagination]);  

 



 
  const handleChange = (event) => {
    setQueryParams(prev => ({
      ...prev,
      'table':{
        ...prev['table'], 
        sortBy:event.target.value
      }
    }))
    setLoading(prev => ({ 
      ...prev,
      table: true
    }));
    setAxiosData(prev => ({
      ...prev,
      restMethod: "GET",
      restRoutePath: "result-table",
      postRoute: "table",
      run:!prev.run
    }))
  };

  const handleOrderBy = (value) => {
    setQueryParams(prev => ({
      ...prev,
      'table':{
        ...prev['table'], 
        orderBy:value
      }
    }))
    setAxiosData(prev => ({
      ...prev,
      restMethod: "GET",
      restRoutePath: "result-table",
      postRoute: "table",
      run:!prev.run
    }))
  };


 
  useEffect(() => { 
    if(!popupModalWorkout){
      setPopupBackground((prev) => !prev)
    }
}, [popupModalWorkout]); 

  const handlePopupModalWorkout = () =>{

        if(!popupModalWorkout){
          setAxiosData(prev => ({
            ...prev,
            restMethod: "GET",
            restRoutePath: "new-workout",
            postRoute: "new-workout",
            run: !prev.run
          }))
          setPopupModalWorkout((prev) => !prev)
          setPopupBackground((prev) => !prev)
        }else{

        }
        
  }

  const result_count = (1* 10);

  const handleButtonDate = (event) =>{
    const name = event.target.name;
    setQueryParams(prev => ({
      ...prev,
      'table':{
        ...prev['table'], 
        select:name
      }
    }))

    setAxiosData(prev => ({
      ...prev,
      restMethod: "GET",
      restRoutePath: "result-table",
      postRoute: "table",
      run:!prev.run
    }))

  }


  const handleFilterOptions = (event) => {
    const {name, value} = event.currentTarget;
    
    setFilterOptions(prev => ({
      ...prev,
      [name]: value
    }))
   
  }

  return (
    (!initialLoad) && results && (
    <div className={dashboard.container}>
    <div className={dashboard.background}></div>
    <FilterContext.Provider value={{
      axiosData,
      setAxiosData, 
      queryParams, 
      setQueryParams, 
      sendPostData,
      setSendPostData,
      loading, 
      setLoading,
      chartLoading, 
      setChartLoading,
      user
    }}>
   


    <div className={dashboard.table_container}>

    <div className={dashboard['header']}>
    <div className={dashboard['user']}>
      <h3>Overview</h3>
      <div className={dashboard['username']}>Welcome back, <strong>{user.username}</strong></div>  
    </div>

    <ResultMonthHeader
    calendarChartData={calendarChartData} 
    data={resultChart}
    />
    </div>
    
    <div className={dashboard['content-main']}>
    <div>
      <button 
      onClick={handlePopupModalWorkout}
      className={dashboard['activity-button']}>
      <BsCalendar2WeekFill size={16}/>
      Add Activity
      </button>
    </div>

    <div className={dashboard['center']}>
    <div className={dashboard['result-header']}>
      
    <div className={dashboard['search-bar']}>
        <div className={dashboard['button']}><FaSearch /></div>
        <input 
        placeholder="Search workouts"
        type="text" />
      </div>

      <div className={dashboard['navigation']}>
      {pagination && Object.entries(pagination.dates).map(([key, value]) => (
        <div key={key} className={`${dashboard['button-nav']} ${queryParams["table"].select === key && dashboard[true]}`}>
        <button 
        name={key} 
        onClick={handleButtonDate}
        className={`${dashboard['name']}`}>
          {key} ({value})
        </button>
        <button
         name="dateDropdown"
         value={key}
         onClick={handleFilterOptions}
         className={dashboard['filter']}
        >
        <IoFilter />
        </button>

        {filterOptions['dateDropdown'] === key ? (
          <div className={dashboard['useRef']} ref={popupRef}> {/* Attach the ref here */}
          {key === 'daily' && <FilterDay data={tableFilters.day} />}
          {key === 'monthly' &&  <FilterMonth data={tableFilters.month}/>}
          </div>
        ) : null}
        </div>
      ))}
      
      <div className={dashboard['sortBy']}>
        Sort By:
      <select 
      className={dashboard['select-option']}
      value={queryParams.sortBy} 
      onChange={handleChange}
      >
        {/* Placeholder option */}

        {/* Options in the dropdown */}
        <option value="datetime">Date</option>
        <option value="totalJumps">Jumps</option>
        <option value="totalTime">Time</option>
        <option value="totalCalories">Calories</option>
      </select> 
      <div className={dashboard['order-buttons']}>
        <button
        onClick={()=> handleOrderBy('asc')} 
        className={dashboard['button']}>
          <FaCaretUp color={queryParams['table'].orderBy === 'asc' ? "#000" : "#ccc"}/>
        </button>
        <button
        onClick={()=> handleOrderBy('desc')}  
        className={dashboard['button']}>
          <FaCaretDown color={queryParams['table'].orderBy === 'desc' ? "#000" : "#ccc"} />
        </button>
      </div>
      </div>

      </div>
    <div className={dashboard['res-showing']}>Showing 1 - {result_count} of {pagination.results}</div>
    </div>
    <div className={dashboard['table']}>

    {(!loading.table && results) && results.map((item, index) => (
        
          <TableResultItem 
          key={index}
          isSelected={queryParams.table['select']}
          item={item}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          />
       
    ))}
    </div>
    </div>

   
    </div>
      

    <Pagination
      pagination={pagination}
      setPagination={setPagination}
      pages_to_show={7}
      hideButtons={false}
      hideTotalPages={false}
      style_class="table-result"
    />
     



      </div>
     
    </FilterContext.Provider>
    </div>
  )
)
}

export const useFilterContext = () => {
  return useContext(FilterContext);
};

export default PageUserDashboard;
import { useState, createContext } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { style_user } from '../css'
import { BsFillEyeSlashFill, MdError } from '../assets/icons';
import { isEmpty } from '../includes/general';
import { FormCustomInput, FormPasswordStrength } from '../components';
import { validateUsername,validateEmail,getUserBrowser,getUserTimezone } from '../functions/user-functions';
import { firstLetterCapitalize } from '../includes/general';

export const createAccountContext = createContext();

export default function AccountRegister() {

  

  const error_msg = {
    empty:[],
    password_length:'Password needs to be at least 6 characters',
    password_match:"Passwords don't match"
  }
  const [error, setError] = useState(false)


  const [form, setForm] = useState({
    username:"",
    email:"",
    password:"",
    confirm_password:"",
    validation:{username:false, email:false}
  })

  const inputType = {'username':"text", "email":"text", "password":"password", "confirm_password": "password"}
  const inputMaxLength = {'username':20}
  const placeholder = {'username':"Choose a username (2-20)", "email": "Enter email address", "password":"Password", "confirm_password": "Confirm Password"}
  
  const validate = ['username', 'email']





  /* Submit -> Create Account */
  const createAccount = (event) => {
    event.preventDefault();
    let fields = ["username", "email", "password"];
    let errors = []

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
console.log(userTimezone);



    let username = form.username;
    let email = form.email;
    let password = form.password;
    let confirm_password = form.confirm_password;

    fields.map((field) => {
        if(isEmpty(form[field])){
        errors.push(field+ ' is empty')
        }
    })
    
    if(!validateUsername(username)){
        errors.push('username must be between 2 - 20 characters')
    }

    if(!validateEmail(email)){
        errors.push('please enter a valid Email address')
    }

    if(password.length < 6){
        errors.push('password needs to be at least 6 characters')
        console.log('err', password.length)
    }else if(password !== form.confirm_password){
        errors.push("passwords don't match")
    }





    if(isEmpty(errors)){
        setError(false)

    let param = {
       "username":username,
       "email":email,
       "password":password, 
       "confirm_password": confirm_password,
       "web_browser":getUserBrowser(),
       "timezone":getUserTimezone()
    }

    async function addNewUser() {
     
        const handleErrors = (data) =>{
             setError(data['error'])
             //console.log(data)
        }
         

        await axios.post(`/user/create`, param)
        .then(({data}) => {
             if(data.hasOwnProperty("error")){
                 handleErrors(data)
             }else{
                console.log(data)
             }
             
        })
        .catch((error) =>{
            console.log(error)
        })
            
    }
    addNewUser();

    }else{
     setError(errors[0])
    }

      


    } 

 
const showInputError = (field) =>{
    if(!isEmpty(error)){
        if(error.includes(field)){
            return true;
        }else if(error.includes('passwords') && (field === 'password' || field === 'confirm_password')){
            return true;
        }
    }else{
        return false;
    }

}


  return (
    <div className={style_user['container']}>
   
    <div className={style_user['account']}>
    <h5>Sign Up</h5>
   
    <form onSubmit={createAccount} style={{marginTop:20,marginBottom:10}}>
   
 
    <div className={style_user['field-columns']}>
    {['username', 'email', 'password', 'confirm_password'].map((input_field, input_id) => (
    <>
    <div 
    className={`${style_user['input-field-register']} ${showInputError(input_field) && style_user['error']}`}
    >
    <FormCustomInput 
    key={input_id}
    form={form}
    setForm={setForm}
    field={input_field}
    field_icon={input_field}
    placeholder={placeholder[input_field]}
    type={inputType[input_field]}
    maxLength={inputMaxLength[input_field]}
    validate={validate[input_field]}
    />

    {(input_field === 'password') && (
    <BsFillEyeSlashFill size="22" color="#909eb0" className={style_user['validate-icon']}/>
    )}
    </div>

    {(input_field === 'password') && (
        <FormPasswordStrength 
        password={form.password}
        />
    )}
    </>


    ))}
    </div>
    {error && (
     <div className={style_user['error-msg']}><MdError size="15" color="#f35b5b" className={style_user['icon']} />{firstLetterCapitalize(error)}</div> 
    )}

    <span>By continuing, you agree to our <Link className={style_user['confirm-terms']}>Terms</Link> and <Link className={style_user['confirm-terms']}>Privacy Policy</Link></span>
   

    <button type="submit" className={style_user['button-login']}>Create Account</button>
    </form>
     
 

    </div>
 

    

    <div className={style_user['create-account']}>
    Don't have an account?
    <Link to="/register" className={style_user['create-button']}>Register Now</Link>
    </div>

    </div>
  )
}

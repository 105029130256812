import React, {useRef } from 'react'
import styles from './../../css/dashboard/filter-table.module.css';
import { Percentage } from '../../functions/number-functions';

export default function FilterDay({ data }) {

  const day = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"]
  const dayname = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"]

  return (
    <div className={styles['filter-container']}>
       {dayname.map((name, index) => (
        <div key={index} className={styles['block']}>
          
            <div className={styles['value-row']}>
            <div 
            style={{'bottom': data[name] ? Percentage(data[name], data['max'])+'%' : 0}}
            className={styles['count']}>{data[name]}</div>
                <div 
                style={{'height': data[name] ? Percentage(data[name], data['max'])+'%' : 0}}
                className={`${styles['percent-value']}`}></div>
            </div>
            <div className={styles['label']}>{day[index]}</div>
        </div>
       ))}
    </div>
  )
}

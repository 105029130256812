import React from 'react'
import styles from '../css/Pagination.module.css'
import { FaChevronLeft, FaChevronRight  } from 'react-icons/fa';

export default function Pagination({ pagination, setPagination, pages_to_show, hideButtons, hideTotalPages, style_class }) {
    const total_records = pagination.results;
    const recordsPerPage = pagination.records_per_page;
    const currentPage = pagination.current_page;
    const maxPages = pagination.max_pages;
  
    const total_pages = Math.ceil(total_records / recordsPerPage);
    const current_page = currentPage > total_pages ? total_pages : currentPage;
  
    let start_page = Math.max(1, current_page - Math.floor(pages_to_show / 2));
    start_page = start_page === 1 ? 2 : start_page;
    let end_page = Math.min(total_pages, start_page + pages_to_show - 1);
  
    // Adjust start and end pages if needed
    if (end_page === total_pages) {
        start_page = Math.max(1, total_pages - pages_to_show + 1);
    } else if (start_page === 1 && total_pages >= pages_to_show) {
        end_page = pages_to_show - 1;
    }
  
    const Pages = () => {
      const paginationLinks = [];
  
      paginationLinks.push(
        <button 
        key={1} 
        onClick={()=> current_page !== 1 && setPagination(prevData => ({ ...prevData, current_page: 1 }))} 
        className={`${styles['page-number']} ${current_page === 1 && styles['current-page']}`}>1</button>
      );
  
   
      if(start_page !== 2 && total_pages > 4) {
        paginationLinks.push(
          <span key="gap1" className={styles['page-gap']}>...</span>
        );
      }
  
  
  
      // Add pages between start_page and end_page
      for (let i = start_page; i <= end_page; i++) {
        if(i !== 1 && i !== total_pages){
             
          if (i === current_page) {
            paginationLinks.push(
                <button key={i} className={`${styles['page-number']} ${styles['current-page']}`}>{i}</button>
            );
          }else{
            paginationLinks.push(
              <button key={i} onClick={() => setPagination(prevData => ({ ...prevData, current_page: i }))} className={`${styles['page-number']}`}>{i}</button>
              );
          } 
  
              
          }
      }
      // Add ellipsis if there's a gap between end_page and the last page
        if(end_page < total_pages - 1) {
            paginationLinks.push(
              <span key="gap2" className={styles['page-gap']}>...</span>
            );
        }
  
        paginationLinks.push(
          <button 
          key={total_pages} 
          onClick={()=> current_page !== total_pages && setPagination(prevData => ({ ...prevData, current_page: total_pages }))}
          className={`${styles['page-number']} ${current_page === total_pages && styles['current-page']}`}>{total_pages}</button>
        );
      return paginationLinks;
  };
  
  
    const handlePrevNext = (name) => {
        if (name === "prev") {
            if (current_page > 1) {
                
                setPagination(prevData => ({ ...prevData, current_page: current_page - 1 }));
            }
        } else {
            if (current_page < total_pages) {
                setPagination(prevData => ({ ...prevData, current_page: current_page + 1 }));
            }
        }
    };
  
  
    return (
       maxPages > 1 && (
        <div style={{ height: 30 + 'px' }} className={`${styles['pagination']} ${styles[style_class]}`}>
            <nav>
  
                {!hideButtons && (
                <button
                    onClick={() => handlePrevNext("prev")}
                    className={`${styles['button-prev']} ${currentPage === 1 && styles['disabled']}`}>
                    <FaChevronLeft />
                </button>
                )}
  
                <div className={styles['pages']}>{Pages()}</div>
  
                 {!hideButtons && (
                  <button
                    onClick={() => handlePrevNext("next")}
                    className={`${styles['button-next']} ${currentPage === maxPages && styles['disabled']}`}>
                    <FaChevronRight />
                </button>
                 )}
  
            </nav>
            {!hideTotalPages && (
            <div className={`${styles['total-pages']}`}>Page {current_page + ' of ' + total_pages}</div>
            )}
        </div>
          )
    );
  }
  
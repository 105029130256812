import  React, { useContext, useState, useEffect } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import style from './css/Header.module.css'
import { RiQuestionFill } from 'react-icons/ri';
import UserHeader from './components/dashboard/user-header';
import { UserContext } from './App';



export default function Header() {

  const {user} = useContext(UserContext);
  const[url, setUrl] = useState('');

  useEffect(() => {
    if (window.location && window.location.href) {
      let url = new URL(window.location.href);
      let modified_url = url.pathname;
      if (url.pathname.endsWith("/")) {
        modified_url = url.pathname.slice(0, -1);
      }

      setUrl(modified_url);
    }
  }, []);

  function CustomLink({ to , children, ...props}){
    const urlPath = useResolvedPath(to)
    const isActive = useMatch({path: urlPath.pathname, end: true})
    return(
        <Link className={isActive ? style['active'] : style['default']} to={to} {...props}>{children}</Link>
    )
  }

  function convertToSlug(str){
    let url_link = str.toLowerCase().replace(/ /g, "-");
    return '/user/'+url_link;
  }

  return (
    <header className={style.header}>

      <div className={style['column-main']}>
    
    <div className={style.navigation}>
      <Link to="/" className={style['site-title']}>Discover The Jump</Link>
   
     
    
    <div className={style.rightNavUser}>
      {user ? 
       <UserHeader /> :
    <Link to="/account/login" className={style.userButton}>
         <span>Login</span>
    </Link> 
  }
    </div>
    
    </div>
    </div>

    

    </header>
  )
}

import React from 'react'
import styles from '../../css/dashboard/milestone-chart.module.css'
import { GiKangaroo,GiHighGrass } from "react-icons/gi";
import { useFilterContext } from '../../pages/logged-in/PageUserDashboard';
import { formatNumberK } from '../../functions/number-functions';

export default function MilestoneChart({ data }) {

  const { axiosData, setAxiosData, setQueryParams } = useFilterContext()

  const milestones = [0,20,40,60,80];

 const valueRanges = data['kangaroo']['ranges'];

 const handleMonthToggle = (number, year) =>{
   

  setQueryParams(prev => ({ 
    ...prev,
    chartMilestones:{fetch: "milestones", month:number, year: year}
  }))
  setAxiosData({
    restMethod: "GET",
    restRoutePath: "chart",
    postRoute: "chartMilestones",
    run: !axiosData.run
  })
}

 
  return (
    <div className={styles['container']}>
        
        
        <div className={styles['main']}>
        <div className={styles['header']}></div>
        <div className={styles['content-chart']}>
        {milestones.map((number, index) => (
            <div 
            key={index}
            className={styles['milestone-column']}>

                {valueRanges.hasOwnProperty(number) && (
                <div className={styles['markers']}>{formatNumberK(Math.max(...valueRanges[number]), 100000)}</div>
                )}

 
                <div className={styles['percent-marker']}>
                  <GiHighGrass className={styles['grass-icon']} size={25} color="499C60" />
                  <div className={styles['label']}>{number}%</div>
                </div>

            </div>
        ))}
        <div 
         style={{ left: `calc(${data['kangaroo']['position']+'%'} - 15px)` }}
        className={styles['kangaroo']}>
        <GiKangaroo size={40} color="#ba7902"/>
        </div>
        
        <div className={styles['position-bar']}>

           <div 
           style={{'width':data['kangaroo']['position']+'%'}}
           className={styles['current-pos']}></div>
        </div>

        </div>
        </div>


        
        <div className={styles['footer']}>
        
        <button 
        name="prev"
        onClick={()=> data.prevButton['found'] ? handleMonthToggle(data.prevButton['number'], data.prevButton['year']) : null}
        className={`${styles['button-prev']} ${styles[data.prevButton['found']]}`}>
          {data.prevButton['name']} '{data.prevButton['year']}
        </button>

        <div className={styles['content-grid']}>
          
          <div className={styles['column']}>
          <strong>{data.month}</strong>
          {data.year}
          </div>

          <div className={styles['column']}>
          <strong>{data.totalCount}</strong>
          Activity Days
          </div>

          <div className={styles['column']}>
          <strong>{data.totalJumps}</strong>
          Total Jumps
          </div>

          <div className={styles['column']}>
          <strong>{data.totalTime}</strong>
          Total Time
          </div>

          <div className={styles['column']}>
          <strong>{data.totalCalories}</strong>
          Total Calories
          </div>


        </div>
        <button 
        onClick={()=> data.nextButton['found'] ? handleMonthToggle(data.nextButton['number'], data.nextButton['year']) : null}
        className={`${styles['button-next']} ${styles[data.nextButton['found']]}`}>
          {data.nextButton['name']} '{data.nextButton['year']}
        </button>
      </div>
    </div>
  )
}

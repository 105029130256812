
export function isUserLoggedIn() {
  if (typeof window !== 'undefined') { // Ensure the code runs only in the browser
    const user = localStorage.getItem('user');

    if (user === null || user === '') {
      return false;
    }

    try {
      const obj_data = JSON.parse(user);
      // Check if obj_data is a non-empty object
      if (obj_data && typeof obj_data === 'object' && Object.keys(obj_data).length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      // If JSON.parse fails, the data is not valid JSON, hence return false
      return false;
    }
  }
  return false; // Default to false if window is not defined
}

function getUserID(){

  if(localStorage.getItem('user')){
      let obj = window.localStorage.getItem("user");
      let obj_data = JSON.parse(obj);
      return obj_data['user_id'];
  }
}

function getUsername(){

    if(localStorage.getItem('user')){
        let obj = window.localStorage.getItem("user");
        let obj_data = JSON.parse(obj);
        return obj_data['user'];
    }
}

function getUserDate(){

  if(localStorage.getItem('user')){
      let obj = window.localStorage.getItem("user");
      let obj_data = JSON.parse(obj);
      return obj_data['user_date'];
      
  }
}

function getUserTotals(key){

  if(localStorage.getItem('user_totals')){
      let obj = window.localStorage.getItem("user_totals");
      let obj_data = JSON.parse(obj);
      return obj_data[key];
      
  }
}

function getUserEmail(){

  if(localStorage.getItem('user')){
      let obj = window.localStorage.getItem("user");
      let obj_data = JSON.parse(obj);
      return obj_data['user_email'];   
  }
}

export {
  getUserID,
  getUsername,
  getUserEmail,
  getUserDate,
  getUserTotals
}

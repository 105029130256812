import { useState, useEffect, createContext, useContext } from 'react';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {PageHome, 
        PageAbout, 
        PageLogin, 
        PageRegister, 
        PageUserDashboard,
        UserSettings, 
        PagePrivacyPolicy, 
        PageNotFound
      } from './pages';
      
import Footer from './Footer';
import Header from './Header';
import { isUserLoggedIn } from './getUser';
import { isEmpty } from './includes/general';

export const UserContext = createContext();

function App() {

  const isLoggedIn = isUserLoggedIn()
 
  const location = useLocation();
  const myParam = new URLSearchParams(location.search).get("summary");

  const [user, setUser] = useState(false)
  const [popupBackground, setPopupBackground] = useState(false)

  const navigate = useNavigate();
 
  var path_name = location.pathname.slice(1)
  path_name = path_name.replace(/\//g, "_");
  if(path_name.endsWith("_")) {
    path_name = path_name.slice(0, -1);
  }



  const isPageHome = () =>{
    if(location.pathname === '/'){
      return 'home';
    }else{
      return 'default';
    }
  }

  useEffect(() => {
    
    let notLoggedIn = ["account_login", "account_signup"]

    if(isLoggedIn && notLoggedIn.includes(path_name)){
      navigate('/home');
    }

     
  },[path_name])

  const style_theme = () =>{
    switch(path_name){
    case 'settings':
    case 'user_workout':  
    return 'theme_dark'
    case 'about':
    case 'account_login':  
    return 'theme_light';
    break;
    case 'user_create-challenge':  
    return 'create-goal-theme';
    default:
    }
}



 // Effect to load user data on initial render
 useEffect(() => {
  if (isLoggedIn){
    const obj = window.localStorage.getItem("user");
    const userData = JSON.parse(obj);
    setUser(prevUser => {
      const currentUser = { ...prevUser, loggedIn: true };
  
      // Loop through keys in userData and set them in newUser
      for (const key in userData) {
        currentUser[key] = userData[key];
      }
  
      return currentUser;
    });

  }else{
    localStorage.removeItem('user');
    setUser(false);
  }

}, []);

  return (
    <div className="App">
 
    <UserContext.Provider value={{setPopupBackground, user, setUser}}>
      <Header />

      <div className={`main ${isEmpty(style_theme()) ? isPageHome() : style_theme()}`}>
      <Routes>
      <Route exact path="/" element={<PageHome />}/>
      <Route exact path="/about" element={<PageAbout />}/>
      <Route exact path="/privacy-policy" element={<PagePrivacyPolicy />}/>
      {user ? 
      <>
      <Route exact path="/dashboard" element={<PageUserDashboard />}/>
      <Route exact path="/settings" element={<UserSettings />}/>
      </>
      :
      <>
      <Route exact path="/account/login" element={<PageLogin />}/>
      <Route exact path="/account/signup" element={<PageRegister />}/>
      </>
      }
      <Route path="*" element={<PageNotFound />} />
      </Routes>

      {/*
      <div className="path-wave">
         <div className="path"></div>
      </div>
      */}  
      </div>
      

    </UserContext.Provider>
    </div>
  );
}

export const useUserContext = () => {
  return useContext(UserContext);
};

export default App;

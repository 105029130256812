import React, { useState, useEffect } from 'react'
import styles from '../../css/dashboard/resultMonthHeader.module.css'
import CalendarChart from '../charts/CalendarChart'
import MilestoneChart from '../charts/MilestoneChart'
import ActivityMapChart from '../charts/ActivityMapChart'
import { useFilterContext } from '../../pages/logged-in/PageUserDashboard'

export default function ResultMonthHeader({ 
  calendarChartData,
  data 
}) {

  const { axiosData, setAxiosData, setQueryParams, queryParams, user, chartLoading, setChartLoading } = useFilterContext()

  const [navLink, setNavLink] = useState('calendar');

  const handleButtonNav = (event) =>{
    const name = event.target.name;
    const chart = event.target.value;
    if(navLink !== name){
       setChartLoading(true)
       setNavLink(name)
       setQueryParams(prev => ({
        ...prev,
        [chart]: {
          fetch: name
        }
       }))
       setAxiosData({
        restMethod:"GET",
        restRoutePath: "chart",
        postRoute: chart,
        run:!axiosData.run
       })
    }

  }


   
  const title = {"calendar": "chartCalendar", "milestones": "chartMilestones", "map":"chartMap"}
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <nav>
         {['calendar','milestones','map'].map((name, index) => (
          <button
          name={name}
          value={title[name]} 
          onClick={handleButtonNav}
          key={index} className={`${styles['nav-button']} ${navLink === name && styles[true]}`}>
            {name}
          </button>
        ))}
        </nav>
      </div>
      
      {!chartLoading && (
      
      (navLink === 'calendar') ? (
      <CalendarChart data={calendarChartData}/>
      ) : navLink === 'milestones' ?  (
      <MilestoneChart data={data}/>
      ) : (
      <ActivityMapChart data={data}/> 
      )

      )}
 
          
      
  
    </div>
  )
}
